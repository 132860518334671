<template>
  <section>
    <b-row>
      <b-col>
        <repeat-statistics :data="itemsData" size="3" />
      </b-col>
    </b-row>

    <b-card>
      <div class="card-header">
        <!-- Title & SubTitle -->
        <div>
          <b-card-title>{{ title }}</b-card-title>
          <b-card-sub-title>{{ subtitle }}</b-card-sub-title>
        </div>
        <!-- Card Actions -->
        <div class="heading-elements">
          <ul class="list-inline">
            <li>
              <feather-icon icon="HelpCircleIcon" v-b-toggle.sidebar-1 size="20" />
            </li>
          </ul>
        </div>
      </div>
      <div>
        <b-sidebar id="sidebar-1" title="Detalle de Mezcla" shadow bg-variant="dark" text-variant="light" width="450px"
          right backdrop>
          <div class="px-2 py-2">
            <p>
              En esta secci&oacute;n se puede visualizar dentro de una tabla el detalle de la mezcla que usted ha seleccionado
              con todas las variables de salida del blending.
            </p>
            <p>
              Dentro de ellas encontramos las restriccones entregadas, concentrado de Mo, Cu, Fe, W, Ca, Si, la humedad
              (H2O), entre otros.
            </p>
            <p><strong>Descargar detalle:</strong> Para poder descargar el detalle debe oprimir el bot&oacute;n “Descargar”,
              donde se bajar&aacute; en su computadora local un archivo excel con el detalle completo de la mezcla.
            </p>
          </div>
        </b-sidebar>
      </div>
      <b-row>
        <b-col sm="12">
          <ag-grid-table ref="grid-detalle-mezcla" :configUrl="detallesMezclaConfig" :dataUrl="detallesMezclaData"
            :download="true">
          </ag-grid-table>
        </b-col>
      </b-row>
    </b-card>
    <b-button :to="{ name: 'molymet-sugerencia-mezcla' }"
      style="height: 50px; background-color: #053743 !important; float:right; margin-right: 20px;  margin-top: 20px; padding-top: 17px;">
      Volver a Sugerencia de Mezcla</b-button>
  </section>
</template>

<script>
import {
  BTableSimple,
  BCard,
  BRow,
  BCol,
  BButton,
  BLink,
} from "bootstrap-vue";

import axios from "@axios";
import useApiServices from '@/services/useApiServices.js';
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue';
import RepeatStatistics from "@/views/clients/camanchaca/components/RepeatStatistics";

export default {

  components: {
    BTableSimple,
    BCard,
    AgGridTable,
    BRow,
    BCol,
    BButton,
    BLink,
    RepeatStatistics,
  },

  data() {
    return {

      mezcla: null,
      detallesMezclaConfig: null,
      detallesMezclaData: null,
      title: null,
      subtitle: null,
      itemsData: [],

    }
  },
  mounted() {
    this.updateCards();
  },
  beforeMount() {

    this.mezcla = this.$route.params.mezcla;
    this.fecha = this.$route.params.fecha;
    // console.log(this.fecha);
    this.title = 'Mezcla_' + this.fecha;
    this.detallesMezclaConfig = useApiServices.detallesMezclaConfig + "/" + this.mezcla + "/" + this.fecha;
    this.detallesMezclaData = useApiServices.detallesMezclaData + "/" + this.mezcla + "/" + this.fecha;
  },

  created() {
  },
  methods: {
    updateCards() {
      axios.get(useApiServices.detallesMezclaStatistics + "/" + this.mezcla + "/" + this.fecha)
        .then((response) => {
          this.itemsData = response.data;
        })
        .catch((error) => {

        });
    }

  },
};

</script>

